import React, { useState, useEffect } from 'react';
import './HeaderComponent.css'; // Local css file for HeaderComponent
import Teknoozu2 from '../Assets/Teknoozu2.png'; // Local image file for Teknoozu Logo
// import { Link } from 'react-router-dom';

function HeaderComponent() {
  const [isSticky, setIsSticky] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth >= 769) {
        setIsSticky(window.scrollY > 0);
      } else {
        setIsSticky(false); // Ensure sticky class is removed in mobile view
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <header className={`header ${isSticky ? 'sticky' : ''}`}>
      <div className="logo">
        <a href="https://teknoozu.org">
          <img src={Teknoozu2} alt="Teknoozu Logo" className="logo-image" />
        </a>
      </div>
      <div className="separator"></div>
      <nav className="navigation">
        <ul>
          <li><a href="#events">Events</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#partnership">Partnership</a></li>
        </ul>
      </nav>
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      {isMobileMenuOpen && (
        <nav className="mobile-nav">
          <ul>
            <li><a href="#events" onClick={toggleMobileMenu}>Events</a></li>
            <li><a href="#about" onClick={toggleMobileMenu}>About</a></li>
            <li><a href="#partnership" onClick={toggleMobileMenu}>Partnership</a></li>
          </ul>
        </nav>
      )}
    </header>
  );
}

export default HeaderComponent;

import React from "react";
import HeaderComponent from './HeaderComponent';
import FooterComponent from './FooterComponent';
import './MainComponent.css'; // Local css file for MainComponent
import image1 from '../Assets/image-1.jpg';
import image2 from '../Assets/image-2.jpg';
import image3 from '../Assets/image-3.jpg';
import image4 from '../Assets/image-4.jpg';
import profilePic1 from '../Assets/profilePic-1.png'; 
import profilePic2 from '../Assets/profilePic-2.png';
import profilePic3 from '../Assets/profilePic-3.png';
import partner1 from '../Assets/BCSLogo.jpg'; 
import partner2 from '../Assets/SHULogo.jpg';
import partner3 from '../Assets/SYLogo.jpg';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function MainComponent() {
  return (
    <React.Fragment>
      <HeaderComponent>
        {/* <Routes>
            <Route path="/" element={<MainComponent />} />
        </Routes> */}
      </HeaderComponent>
      <div id="home" className="main-content">
        <h1>Empowering the future of technology</h1>
        <p>We equip students with tech knowledge through impactful collaborations with universities and professional organizations. Explore upcoming events, discover learning resources, and join our community of passionate tech enthusiasts!</p>
        <button className="explore-button" onClick={() => document.getElementById('events').scrollIntoView({ behavior: 'smooth' })}>Explore Now</button>
      </div>
      <div className="metrics-content">
        <ul>
          <li>
            <div className="metric">
              <span className="metric-value">80+</span>
              <div/>
              <span className="metric-desc">Students mentored</span>
              <div className="divider"></div>
            </div>
          </li>
          <li>
            <div className="metric">
              <span className="metric-value">20+</span>
              <div/>
              <span className="metric-desc">Projects completed</span>
              <div className="divider"></div>
            </div>
          </li>
          <li>
            <div className="metric">
              <span className="metric-value">99+</span>
              <div/>
              <span className="metric-desc">Reviews given</span>
            </div>
          </li>
        </ul>
      </div>
    <div>
      <div className="div-header">
        <div id="events"></div>
      <h3>Upcoming Event</h3>
      </div>
      <div className="event-section">
        <div className="event-description">
          <h3>Ready to shape the future with cutting-edge technology?</h3>
          <p>
          Join us for SmartPulse: Data Hack for a Healthier Future, where you'll dive into the essentials 
          and groundbreaking opportunities in data analysis, IoT, Quantum AI, and cloud services. 
          Whether you're a novice or looking to elevate your tech skills, this event is designed to provide 
          you with the insights and hands-on experience to:
          </p>
          <ul>
            <li>Grasp the core concepts and practices of data analysis, IoT, Quantum AI, and cloud computing.</li>
            <li>Discover innovative solutions that leverage technology for a healthier future.</li>
            <li>Engage in interactive challenges and immersive workshops led by industry experts and experienced SHU faculty.</li>
          </ul>
          <button className="register-button" onClick={() => window.open ('https://cloudeventhallam.azurewebsites.net', '_blank')}>Register</button>
        </div>
        <div className="event-image">
          <div className="image-stack">
            <div className="image-card"><img src={image4} alt="SmartPulse Event" /></div>
            <div className="image-card"><img src={image1} alt="Cloud Challenge" /></div>
            <div className="image-card"><img src={image3} alt="Event Image 3" /></div>
          </div>
        </div>
      </div>
    </div>
    <div id="about" className="about-content">
      <div className="div-header2">
        <h3>About Us</h3>
      </div>
      <div className="about-us">
          <div className="team-section">
            <div className="team-card">
              <img src={image1} alt="Team Member 1" className="team-image" />
              <div className="team-info">
                <h4>Vincent Chidike</h4>
                {/* <p>MSc Computing</p> */}
                <p>Software Developer</p>
                <p>UI/UX Designer</p>
                <img src={profilePic1} alt="Vincent Chidike" className="profile-pic" />
              </div>
            </div>
            <div className="team-card2">
              <img src={image2} alt="Team Member 2" className="team-image" />
              <div className="team-info">
                <h4>Chima Chukwutoo-Ihueze</h4>
                {/* <p>MSc Computing</p> */}
                <p>Software Engineer</p>
                <p>Database Engineer</p>
                <img src={profilePic2} alt="Chima Chukwutoo-Ihueze" className="profile-pic" />
              </div>
            </div>
            <div className="team-card3">
              <img src={image3} alt="Team Member 3" className="team-image" />
              <div className="team-info">
                <h4>Sullivan Nnaji</h4>
                {/* <p>MSc Computing</p> */}
                <p>Cloud Engineer</p>
                <p>DevOps Engineer</p>
                <img src={profilePic3} alt="Sullivan Nnaji" className="profile-pic" />
              </div>
            </div>
          </div>
          <div className="about-description">
            <h3>Community of Tech Professionals</h3>
            <p>We're Teknoozu, a team dedicated to shaping the future of tech. We bridge the gap between education and careers by hosting impactful tech events in partnership with universities and professional organizations. Our LinkedIn group community is where we share valuable resources and stay current with the latest technological advancements.</p>
            <button className="join-button" onClick={() => window.open ('https://www.linkedin.com/groups/13071110/', '_blank')}>Join our community</button>
          </div>
      </div>
    </div>
    <div>
      <div id="partnership" className="div-header">
      <h3>Partnership</h3>
      </div>
      <div className="partnership-content">
        <ul>
          <li>
            <div className="partnership">
              <span className="partnership-value"><img src={partner1} alt="BCS Logo" className="profile-logo" /></span>
              <div/>
              <div className="divider"></div>
            </div>
          </li>
          <li>
            <div className="partnership">
              <span className="partnership-value"><img src={partner2} alt="Sheffield Hallam Uni Logo" className="profile-logo2" /></span>
              <div/>
              <div className="divider"></div>
            </div>
          </li>
          <li>
            <div className="mpartnership">
              <span className="partnership-value"><img src={partner3} alt="Yorkshire DevOps Logo" className="profile-logo" /></span>
              <div/>
            </div>
          </li>
        </ul>
      </div>
    </div>
    {/* <div id="contact" className="contact-content">
        <div className="contact-form">
          <h3>Contact Us</h3>
          <form>
            <input type="text" placeholder="First Name" />
            <input type="text" placeholder="Last Name" />
            <input type="text" placeholder="Phone number" />
            <input type="email" placeholder="Your email" />
            <textarea placeholder="Message"></textarea>
            <button type="submit">Submit</button>
          </form>
        </div>
        <div className="subscribe-section">
          <h3>Stay In The Loop</h3>
          <p>Subscribe to our mailing service to stay up to date with upcoming events and available mentorship from tech professionals and organisations.</p>
          <div className="subscribe-input-group">
            <input type="email" placeholder="Enter your email address.." />
            <button type="submit">Subscribe Now</button>
          </div>
        </div>
      </div> */}
      <FooterComponent />
    </React.Fragment>
  );
}

export default MainComponent;
